
/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />
/// <reference path="../../services/assets.services.ts" />

declare function unescape(s: string): string;
class addAssetCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$routeParams', 'socketService', 'toastr'];
    loading: boolean = false;
    showjson: boolean = false;
    id: string;
    collection: string;
    service: socketServiceClass;
    genericServices: GenericServiceClass;
    assetsServices: AssetsServiceClass;

    model: any;
    cansave: boolean = false;
    jsonmodel: string;
    keys: string[];
    newkey: string;
    keyValue: string;
    toastrObj: any;
    constructor(
        public $scope,
        public $location,
        public $routeParams,
        public socketService: socketServiceFactory,
        public toastr
    ) {

        $scope.togglePassword = function () { $scope.typePassword = !$scope.typePassword; };

        this.toastrObj = toastr;
        this.id = $routeParams.id;
        if (this.id)
            this.cansave = true;
        this.collection = "assets";
        this.service = socketService.namespace;
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.assetsServices = new AssetsServiceClass(this.service.socket, this.service.namespace)

        if (this.collection == 'assets') {
            var user: any = this.socketService.user;
            if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == assetsRoleId)) {
                this.$location.path("/")
                this.$scope.$apply();
            }
        }

        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        }
        else if (this.collection == 'users') {
            this.service = socketService.namespace;
        }
        this.socketService.onSignedin(() => {

            this.model = {};
            this.model.AssetID = "";
            this.model.Description = "";
            this.keys = Object.keys(this.model);

        });
    }
    newuser: string = '';
    newusermodel: any;
    canaddUser: boolean = false;

    async validateuser() {
        this.canaddUser = false;
        if (this.newuser.length > 0) {
            var query = "user eq '" + this.newuser + "' or username eq '" + this.newuser + "' or name eq '" + this.newuser + "'";
            //console.log(query);
            var items = await this.genericServices.get(query, 1, null, null, null, 'users');
            // console.log(items.length);
            if (items.length == 1) {
                this.canaddUser = true;
                this.newusermodel = items[0];
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        }
    }



    assetID: string = '';
    assetIDModel: any;
    canadd: Boolean = true;

    async validatename(name: any) {
        this.assetID = name;


        //var query = "AssetName eq '" + this.assetName + "'";
        if (this.assetID && this.assetID.length > 3) {
            var query = "AssetID eq '" + this.assetID + "' and _id ne '" + this.id + "'";
            //console.log(query);
            var items = await this.genericServices.get(query, 1, null, null, null, 'assets');
            // console.log(items.length);
            if (items.length == 0) {
                this.canadd = true;
                this.assetIDModel = items[0];
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                this.canadd = false;

                if (!this.$scope.$$phase) { this.$scope.$apply(); }

            }
        }
        else {
            this.canadd = false;

            if (!this.$scope.$$phase) { this.$scope.$apply(); }

        }
    }
    goback() {
        this.$location.path("/assets");
    }

    removeuser(_id) {
        for (var i = 0; i < this.model._acl.length; i++) {
            if (this.model._acl[i]._id == _id) {
                this.model._acl.splice(i, 1);
                //this.model._acl = this.model._acl.splice(index, 1);
            }
        }
    }
    adduser() {
        var ace = {
            deny: false,
            _id: this.newusermodel._id,
            name: this.newusermodel.name,
            rights: "//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
        }
        //console.log(this.model._acl);
        this.model._acl = [];
        this.model._acl.push(ace);
    }
    isBitSet(base64: string, bit: number): boolean {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        return (currentValue & mask) != 0;
    }
    setBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue | mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    unsetBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue &= ~mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    toogleBit(a: any, bit: number) {
        //console.log('toogleBit: ' + bit);
        // var buf = this._base64ToArrayBuffer(a.rights);
        // var view = new Uint8Array(buf);
        // console.log(view);
        if (this.isBitSet(a.rights, bit)) {
            a.rights = this.unsetBit(a.rights, bit);
        } else {
            a.rights = this.setBit(a.rights, bit);
        }
        //  var buf2 = this._base64ToArrayBuffer(a.rights);
        // var view2 = new Uint8Array(buf2);
        //console.log(view2);
    }
    _base64ToArrayBuffer(string_base64): ArrayBuffer {
        var binary_string = window.atob(string_base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            //var ascii = string_base64.charCodeAt(i);
            var ascii = binary_string.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes.buffer;
    }
    _arrayBufferToBase64(array_buffer): string {
        var binary = '';
        var bytes = new Uint8Array(array_buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary);
    }

    async add_or_update() {
        try {
            this.loading = true;
            if (this.showjson) {
                this.model = JSON.parse(this.jsonmodel);
            }
            clearError(this.$scope);
            //var t0 = performance.now();

            if (this.model._acl) {
                this.model._acl.forEach((ace, index) => {
                    delete this.model._acl[index].$$hashKey;
                });
            }


            this.model._acl = [];
            await this.assetsServices.addAssetService(this.model, this.collection);

            this.$location.path("/assets");
            if (!this.$scope.$$phase) { this.$scope.$apply(); }

        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
            this.loading = false
        }
    }
    removekey(key) {
        if (this.keys.indexOf(key) > -1) {
            this.keys.splice(this.keys.indexOf(key), 1);
        }
        delete this.model[key];
        var x = Object.keys(this.model).length;
        console.log(x);
        if (this.id == null && Object.keys(this.model).length <= 2)
            this.cansave = false;
        if (this.id && Object.keys(this.model).length === 13)
            this.cansave = false;

    }
    //updated to take the key and value together and add is disabled is one value is empty
    addkey(value: string) {
        if (!this.newkey || this.newkey === '') {
            return;
        }
        if (this.keys.indexOf(this.newkey) > -1) {
            // this.keys.splice(this.keys.indexOf(this.newkey), 1);
            
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage("Please enter unique key"));
            return;
        }
        this.newkey = this.newkey.split(' ').join('');
        value = value.split(' ').join('');
        if (this.newkey.startsWith("_"))
            this.newkey = this.newkey.split("_").join("");
        this.keys.push(this.newkey);
        this.model[this.newkey] = value;
        this.keyValue = '';
        this.newkey = '';

        if (Object.keys(this.model).length > 2)
            this.cansave = true;
    }
    togglejson() {
        this.showjson = !this.showjson;
        if (this.showjson) {
            this.jsonmodel = JSON.stringify(this.model, null, 2);
        } else {
            this.model = JSON.parse(this.jsonmodel);
        }
    }
    prettyDate(time) {
        try {
            var date = new Date(Date.parse(time));
            var diff = (((new Date()).getTime() - date.getTime()) / 1000);
            var day_diff = Math.floor(diff / 86400);
            //console.log('date: %s', date);
            // console.log('diff: %s', diff);
            //console.log('day_diff: %s', day_diff);
            if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) { return this.toFormatedDate(time) }
            return day_diff == 0 && (diff < 60 && Math.floor(diff) + " sec ago" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff == 1 && "Yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago";
        }
        catch (err) {
            // console.log(err);
            return time;
        }
    }
    toLocaleDateString(time) {
        return new Date(time).toLocaleDateString();
    }

    toFormatedDate(time) {
        var myDate = new Date(time);
        return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear();

    }

    parseAgent(agent) {
        if (agent.indexOf('iPad') > -1) {
            agent = 'iPad';
        }
        else if (agent.indexOf('iPhone') > -1) {
            agent = 'iPhone';
        }
        else if (agent.indexOf('ggpht.com') > -1) {
            agent = 'Google Webmail';
        }
        else if (agent.indexOf('Safari') > -1) {
            agent = 'Safari';
        }
        else if (agent.indexOf('Mac OS') > -1 && agent.indexOf('Outlook') > -1) {
            agent = 'Mac OS / Outlook';
        }
        else if (agent.indexOf('Mac OS') > -1) {
            agent = 'Mac OS';
        }
        else if (agent.indexOf('Outlook') > -1) {
            agent = 'Microsoft Outlook';
        }
        else if (agent.indexOf('Microsoft Outlook') > -1) {
            agent = 'Microsoft Outlook';
        }
        else if (agent.indexOf('MSIE') > -1) {
            agent = 'Internet Explorer';
        }
        else {
            //console.log(agent);
            agent = 'unknown';
        }
        return agent;
    };


}
class editAssetCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$routeParams', 'socketService', 'toastr'];
    loading: boolean = false;
    showjson: boolean = false;
    id: string;
    collection: string;
    service: socketServiceClass;
    genericServices: GenericServiceClass;
    assetsServices: AssetsServiceClass;

    model: any;
    cansave: boolean = false;
    jsonmodel: string;
    keys: string[];
    newkey: string;
    keyValue: string;
    toastrObj: any;
    constructor(
        public $scope,
        public $location,
        public $routeParams,
        public socketService: socketServiceFactory,
        public toastr
    ) {

        $scope.togglePassword = function () { $scope.typePassword = !$scope.typePassword; };

        this.toastrObj = toastr;
        this.id = $routeParams.id;
        if (this.id)
            this.cansave = true;
        this.collection = "assets";
        this.service = socketService.namespace;
        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.assetsServices = new AssetsServiceClass(this.service.socket, this.service.namespace)

        if (this.collection == 'assets') {
            var user: any = this.socketService.user;
            if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == assetsRoleId)) {
                this.$location.path("/")
                this.$scope.$apply();
            }
        }

        if (this.collection == 'workflows') {
            this.service = this.socketService.namespace;
        }
        else if (this.collection == 'users') {
            this.service = socketService.namespace;
        }
        this.socketService.onSignedin(() => {
            this.loaddata();
        });
    }
    newuser: string = '';
    newusermodel: any;
    canaddUser: boolean = false;

    async validateuser() {
        this.canaddUser = false;
        if (this.newuser.length > 0) {
            var query = "user eq '" + this.newuser + "' or username eq '" + this.newuser + "' or name eq '" + this.newuser + "'";
            //console.log(query);
            var items = await this.genericServices.get(query, 1, null, null, null, 'users');
            // console.log(items.length);
            if (items.length == 1) {
                this.canaddUser = true;
                this.newusermodel = items[0];
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        }
    }



    assetID: string = '';
    assetIDModel: any;
    canadd: Boolean = true;

    async validatename(name: any) {
        this.assetID = name;


        //var query = "AssetName eq '" + this.assetName + "'";
        if (this.assetID && this.assetID.length > 3) {
            var query = "AssetID eq '" + this.assetID + "' and _id ne '" + this.id + "'";
            //console.log(query);
            var items = await this.genericServices.get(query, 1, null, null, null, 'assets');
            // console.log(items.length);
            if (items.length == 0) {
                this.canadd = true;
                this.assetIDModel = items[0];
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else {
                this.canadd = false;

                if (!this.$scope.$$phase) { this.$scope.$apply(); }

            }
        }
        else {
            this.canadd = false;

            if (!this.$scope.$$phase) { this.$scope.$apply(); }

        }
    }
    goback() {
        this.$location.path("/assets");
    }

    removeuser(_id) {
        for (var i = 0; i < this.model._acl.length; i++) {
            if (this.model._acl[i]._id == _id) {
                this.model._acl.splice(i, 1);
                //this.model._acl = this.model._acl.splice(index, 1);
            }
        }
    }
    adduser() {
        var ace = {
            deny: false,
            _id: this.newusermodel._id,
            name: this.newusermodel.name,
            rights: "//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
        }
        //console.log(this.model._acl);
        this.model._acl = [];
        this.model._acl.push(ace);
    }
    isBitSet(base64: string, bit: number): boolean {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        return (currentValue & mask) != 0;
    }
    setBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue | mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    unsetBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue &= ~mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    toogleBit(a: any, bit: number) {
        //console.log('toogleBit: ' + bit);
        // var buf = this._base64ToArrayBuffer(a.rights);
        // var view = new Uint8Array(buf);
        // console.log(view);
        if (this.isBitSet(a.rights, bit)) {
            a.rights = this.unsetBit(a.rights, bit);
        } else {
            a.rights = this.setBit(a.rights, bit);
        }
        //  var buf2 = this._base64ToArrayBuffer(a.rights);
        // var view2 = new Uint8Array(buf2);
        //console.log(view2);
    }
    _base64ToArrayBuffer(string_base64): ArrayBuffer {
        var binary_string = window.atob(string_base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            //var ascii = string_base64.charCodeAt(i);
            var ascii = binary_string.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes.buffer;
    }
    _arrayBufferToBase64(array_buffer): string {
        var binary = '';
        var bytes = new Uint8Array(array_buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary);
    }
    async loaddata() {
        // var t0 = performance.now();
        clearError(this.$scope);
        try {
            this.loading = true;
            var query = "_id eq '" + this.id + "'";
            var items = await this.genericServices.get(query, 1, null, null, null, this.collection);
            this.model = items[0];
            if (!this.model) {
                var err = { data: { error: { message: "asset with id " + this.id + " was not found!" } } };
                // handleError(this.$scope, err);
                this.toastrObj.error(GetErrorMessage(err));
                return;
            }
            var keys = Object.keys(this.model);
            this.keys = keys;
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));


        }
    }
    async add_or_update() {
        try {
            this.loading = true;
            if (this.showjson) {
                this.model = JSON.parse(this.jsonmodel);
            }
            clearError(this.$scope);
            //var t0 = performance.now();

            if (this.model._acl) {
                this.model._acl.forEach((ace, index) => {
                    delete this.model._acl[index].$$hashKey;
                });
            }
            await this.assetsServices.updateAssetService(this.model, this.collection);
            this.loading = false;
            //  var t1 = performance.now();
            //console.log("Call to update one took " + (t1 - t0) + " milliseconds.");
            this.$location.path("/assets");
            if (!this.$scope.$$phase) { this.$scope.$apply(); }


        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
            this.loading = false
        }
    }
    removekey(key) {
        if (this.keys.indexOf(key) > -1) {
            this.keys.splice(this.keys.indexOf(key), 1);
        }
        delete this.model[key];
        var x = Object.keys(this.model).length;
        console.log(x);
        if (this.id == null && Object.keys(this.model).length <= 2)
            this.cansave = false;
        if (this.id && Object.keys(this.model).length === 13)
            this.cansave = false;

    }
    //updated to take the key and value together and add is disabled is one value is empty
    addkey(value: string) {
        if (!this.newkey || this.newkey === '') {
            return;
        }
        if (this.keys.indexOf(this.newkey) > -1) {
            // this.keys.splice(this.keys.indexOf(this.newkey), 1);
            this.toastrObj.error(GetErrorMessage("Please enter unique key"));
            return;
        }
        this.newkey = this.newkey.split(' ').join('');
        value = value.split(' ').join('');
        if (this.newkey.startsWith("_"))
            this.newkey = this.newkey.split("_").join("");
        this.keys.push(this.newkey);
        this.model[this.newkey] = value;
        this.keyValue = '';
        this.newkey = '';

        if (Object.keys(this.model).length > 2)
            this.cansave = true;
    }
    togglejson() {
        this.showjson = !this.showjson;
        if (this.showjson) {
            this.jsonmodel = JSON.stringify(this.model, null, 2);
        } else {
            this.model = JSON.parse(this.jsonmodel);
        }
    }
    prettyDate(time) {
        try {
            var date = new Date(Date.parse(time));
            var diff = (((new Date()).getTime() - date.getTime()) / 1000);
            var day_diff = Math.floor(diff / 86400);
            //console.log('date: %s', date);
            // console.log('diff: %s', diff);
            //console.log('day_diff: %s', day_diff);
            if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) { return this.toFormatedDate(time) }
            return day_diff == 0 && (diff < 60 && Math.floor(diff) + " sec ago" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff == 1 && "Yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago";
        }
        catch (err) {
            // console.log(err);
            return time;
        }
    }
    toLocaleDateString(time) {
        return new Date(time).toLocaleDateString();
    }

    toFormatedDate(time) {
        var myDate = new Date(time);
        return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear();

    }

    parseAgent(agent) {
        if (agent.indexOf('iPad') > -1) {
            agent = 'iPad';
        }
        else if (agent.indexOf('iPhone') > -1) {
            agent = 'iPhone';
        }
        else if (agent.indexOf('ggpht.com') > -1) {
            agent = 'Google Webmail';
        }
        else if (agent.indexOf('Safari') > -1) {
            agent = 'Safari';
        }
        else if (agent.indexOf('Mac OS') > -1 && agent.indexOf('Outlook') > -1) {
            agent = 'Mac OS / Outlook';
        }
        else if (agent.indexOf('Mac OS') > -1) {
            agent = 'Mac OS';
        }
        else if (agent.indexOf('Outlook') > -1) {
            agent = 'Microsoft Outlook';
        }
        else if (agent.indexOf('Microsoft Outlook') > -1) {
            agent = 'Microsoft Outlook';
        }
        else if (agent.indexOf('MSIE') > -1) {
            agent = 'Internet Explorer';
        }
        else {
            //console.log(agent);
            agent = 'unknown';
        }
        return agent;
    };


}
class entitiesCtrl extends baseEntitiesCtrl {
    ownerName: any;
    isAdmin = false;
    actionsModel: any = {};
    newusermodel: any;
    userbasequery: any;
    userentities: Array<any> = [];
    limit: number = globalLoadData;
    genericServices: GenericServiceClass;
    assetsServices: AssetsServiceClass;
    usercollection: any = 'users';
    newuser: string = '';


    static $inject: Array<string> = ['$scope', '$location', '$compile', '$routeParams', 'socketService'];
    constructor(
        public $scope,
        public $location,
        public $compile,
        public $routeParams,
        public socketService: socketServiceFactory
    ) {
        super($scope, $location, $compile, socketService);
        this.collection = "assets";

        var user: any = this.socketService.user;
        if (!user.roles.find(R => R._id == adminsRoleId) && !user.roles.find(R => R._id == assetsRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }

        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)
        this.assetsServices = new AssetsServiceClass(this.service.socket, this.service.namespace)
        if (this.$location.absUrl().indexOf('/admin/') > -1) {
        }
        else {
            if (this.basequery == '') {
                this.basequery = "_type ne 'system'";
            }
            else {
                this.basequery += " and _type ne 'system'";
            }
        }
        this.userbasequery = "_type eq 'user'";

        this.socketService.onSignedin(() => {
            this.getAssetsPagedData();
        });

        if (user.roles.find(a => a._id == adminsRoleId)) {
            this.isAdmin = true;
        }


    }
    GetAssetsData(pageNumber: number) {
        this.page = pageNumber;
        this.getAssetsPagedData();
    }
    async getAssetsPagedData() {
        this.loading = true;
        clearError(this.$scope);
        this.service = this.socketService.namespace;


        if (!this.genericServices.isConnected()) return;

        var query = this.basequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            query += "contains(AssetID,'" + this.searchstring + "')"

        }
        try {

            // if user not admin then get owned items only  
            var user: any = this.socketService.user;
            if (!user.roles.find(R => R._id == adminsRoleId)) {
                if (query) {
                    query += ' and _createdbyid eq ' + "'" + user._id + "'";
                } else {
                    query = '_createdbyid eq ' + "'" + user._id + "'";
                }

            }

            let projction = { 'AssetID': 1, 'Description': 1, '_modified': 1, '_created': 1 }
            var Result: any = await this.assetsServices.getAssetsPageService(query, projction, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection);
            var items = Result.items;
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);
            this.loading = false;
            this.entities = items;
            this.scrollloading = (items.length > 0);
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    async validateuser() {

        if (this.newuser.length > 0) {
            var query = "user eq '" + this.newuser + "' or username eq '" + this.newuser + "' or name eq '" + this.newuser + "'";
            var items = await this.genericServices.get(query, 1, null, null, null, 'users');
            if (items.length == 1) {
                this.newusermodel = items[0];
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        }
    }

    adduser() {
        var ace = {
            deny: false,
            _id: this.newusermodel._id,
            name: this.newusermodel.name,
            rights: "//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
        }
        //console.log(this.model._acl);


        var exist = false;
        for (var i = 0; i < this.actionsModel._acl.length; i++) {
            if (this.actionsModel._acl[i]._id === this.newusermodel._id) {
                exist = true;
            }
        }
        if (exist === false) {
            this.actionsModel._acl.push(ace);
        }

    }
    removeuser(_id) {
        for (var i = 0; i < this.actionsModel._acl.length; i++) {
            if (this.actionsModel._acl[i]._id == _id) {
                this.actionsModel._acl.splice(i, 1);
                //this.model._acl = this.model._acl.splice(index, 1);
            }
        }
    }

    isBitSet(base64: string, bit: number): boolean {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        return (currentValue & mask) != 0;
    }

    _base64ToArrayBuffer(string_base64): ArrayBuffer {
        var binary_string = window.atob(string_base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            //var ascii = string_base64.charCodeAt(i);
            var ascii = binary_string.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes.buffer;
    }

    async loadusers() {
        clientInformation
        clearError(this.$scope);

        //debugger;
        if (!this.genericServices.isConnected()) return;
        this.loading = true;
        //var t0 = performance.now();
        var query = this.userbasequery;
        if (this.searchstring !== '') {
            if (query != '') { query += " and "; }
            query += "contains(name,'" + this.searchstring + "')";
        }
        try {

            var items = await this.genericServices.get(query, this.limit, null, this.order.field, this.order.reverse, this.usercollection);
            this.loading = false;
            //if (odata.count) { this.inlineCount = odata.count; }
            this.userentities = [];
            items.forEach(member => {

                this.userentities.push({ name: member.name, id: member._id })

            });

            //le.log(this.userentities);
            this.scrollloading = (items.length > 0);
            this.loading = false;
            // var t1 = performance.now();
            // if(logLoadtimes) { 
            //  console.log("Call to loaddata took " + (t1 - t0) + " milliseconds.");
            // }
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            // handleError(this.$scope, err);
            //this.toastrObj.error(GetErrorMessage(err));
        }
    }

    async updatePermissions() {
        await this.genericServices.put(this.actionsModel, 'assets');
    }



}